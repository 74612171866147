import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Structure } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | DriveRight
			</title>
			<meta name={"description"} content={"Ми розуміємо, що початок вашої подорожі на дорозі може бути наповнений питаннями, і ми тут, щоб надати чіткі, стислі відповіді."} />
			<meta property={"og:title"} content={"FAQ | DriveRight"} />
			<meta property={"og:description"} content={"Ми розуміємо, що початок вашої подорожі на дорозі може бути наповнений питаннями, і ми тут, щоб надати чіткі, стислі відповіді."} />
			<meta property={"og:image"} content={"https://blasterspace.live/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://blasterspace.live/images/favicon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blasterspace.live/images/favicon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blasterspace.live/images/favicon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://blasterspace.live/images/favicon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 10px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Поширені запитання - FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Ласкаво просимо на сторінку поширених запитань DriveRight, де ми прагнемо відповісти на всі ваші запитання та надати вам інформацію, необхідну для отримання максимальної користі від навчання водінню. Ми розуміємо, що початок вашої подорожі на дорозі може бути наповнений питаннями, і ми тут, щоб надати чіткі, стислі відповіді.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Які типи програм з водіння ви пропонуєте?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ми пропонуємо цілий ряд програм, включаючи курси для початківців, просунутий курс водіння, навчання підлітків, курси підвищення кваліфікації, спеціалізоване навчання та підготовку до дорожніх тестів. Кожна програма розроблена з урахуванням ваших конкретних потреб.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Як я можу запланувати заняття?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Запланувати заняття дуже просто. Просто зв'яжіться з нами, і наша команда попрацює з вами, щоб знайти зручний час занять, який відповідає вашому графіку.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи сертифіковані ваші інструктори?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Так, усі наші викладачі сертифіковані та досвідчені. Вони прагнуть надати вам найкращу підготовку з водіння.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Які транспортні засоби ви використовуєте для навчання?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ми використовуємо безпечні та комфортні транспортні засоби, обладнані сучасними засобами безпеки, щоб забезпечити безпечний та комфортний процес навчання.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Скільки часу потрібно для завершення програми?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Тривалість залежить від програми та вашого індивідуального прогресу. Наші інструктори будуть працювати з вами, щоб переконатися, що ви готові впевнено вирушити в дорогу.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Яка вартість ваших навчальних програм?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Наші ціни конкурентоспроможні та прозорі. Будь ласка, зв'яжіться з нами для уточнення деталей, оскільки вони можуть відрізнятися в залежності від місця та програми.
					</Text>
				</Box>
			</Box>
		</Section>
		<Structure cells-number-total="2" cells-number-group="1" padding="24px 0px 70px 0px">
			<Override slot="Content" grid-template-columns="1fr">
				<Override slot="cell-1">
					<Link
						href="/contact-us"
						color="--light"
						padding="12px 34px 12px 34px"
						text-decoration-line="initial"
						background="--color-primary"
						border-radius="25px"
						text-align="center"
					>
						Контакти
					</Link>
				</Override>
				<Override slot="cell-0">
					<Text margin="0px 0px 20px 0px" font="--base">
						Ми сподіваємося, що ця сторінка поширених запитань надала вам необхідну інформацію. Якщо у вас залишилися питання або вам потрібна додаткова інформація, будь ласка, звертайтеся до нашої спеціалізованої команди підтримки. Ми готові допомогти вам на кожному кроці.
					</Text>
				</Override>
				<Override slot="Cell 0th" text-align="center" />
			</Override>
		</Structure>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});